

import React from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { IoShareSocialOutline, IoCopyOutline } from 'react-icons/io5';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from 'react-share';
import './ShareButton.css';

const ShareButton = (props) => {
    const { job_title, description,_id } = props.Job_description;
    const removeHTMLTags = (str) => {
        const doc = new DOMParser().parseFromString(str, 'text/html');
        return doc.body.textContent || "";
    };
    
    const truncateDescription = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    const plainTextDescription = removeHTMLTags(description);
    const truncatedDescription = truncateDescription(plainTextDescription, 10);
    const jobImage = "https://front-hrms.dtsmis.in/favicon.png";
    const baseUrl = `https://front-hrms.dtsmis.in/job-details/${_id}?title=${job_title}&description=${truncatedDescription}`;
    const title = `Job Title: ${job_title}\n Description: ${truncatedDescription}`;
     
    const handleCopy = () => {
        navigator.clipboard.writeText(baseUrl)
            .then(() => {
                toast.info('Link copied to clipboard!', {
                    position: "bottom-left",
                    theme: "dark"
                });
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                theme="dark"
            />
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle className="share">
                    <IoShareSocialOutline />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-center">
                    <Dropdown.Item onClick={handleCopy} className="dropdown-item-center">
                        <IoCopyOutline /> Copy Link
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item as="div" className="dropdown-item-center">
                        <FacebookShareButton url={baseUrl} quote={title}  image={jobImage}   className="dropdown-item-center">
                            <FaFacebookF /> Facebook
                        </FacebookShareButton>
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item as="div" className="dropdown-item-center">
                        <TwitterShareButton url={baseUrl} title={title}  image={jobImage}  className="dropdown-item-center">
                            <FaTwitter /> Twitter
                        </TwitterShareButton>
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item as="div" className="dropdown-item-center">
                        <LinkedinShareButton url={baseUrl} title={title}  image={jobImage}   className="dropdown-item-center">
                            <FaLinkedinIn /> LinkedIn
                        </LinkedinShareButton>
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item as="div" className="dropdown-item-center">
                        <WhatsappShareButton url={baseUrl} title={title}  image={jobImage}  className="dropdown-item-center">
                            <FaWhatsapp /> WhatsApp
                        </WhatsappShareButton>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default ShareButton;




