import React  from "react";
import JobSearchForm from "./JobSearchForm/JobSearchForm";
import { useSelector } from "react-redux";




function TopBanner() {

    const webSetting = useSelector(state => state.jobs.webSettingData);


    return (
        <>
            <div className="container-fluid topbanner">
                <div className="container">
                    <div className="jobbanner">
                        <h4>Find Job on {webSetting.status === 'succeeded' && webSetting?.data?.organization_name} </h4>
                        <p className="my-4">Search Jobs opening for which we are hiring right now.</p>
                        <JobSearchForm />
                    </div>
                </div>
            </div>
        </>
    )
}
export default TopBanner;